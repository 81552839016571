* {
    box-sizing: border-box;
}
.fieldArea {
    min-height: 5rem;
    border-radius: 0.4rem;
    position: relative;
    background-color: rgba(255, 255, 255, 0.3);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
}

.fieldArea:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.fieldArea.active {
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}

.fieldArea.active.labelActive textarea {
    padding: 24px 16px 8px 16px;
}

.fieldArea.active textarea + label {
    top: 3px;
    opacity: 1;
    color: #bbb7b7;
}

.fieldArea.locked {
    pointer-events: none;
}

.fieldArea textarea {
    width: 100%;
    min-height: 5rem;
    position: relative;
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    font-family: "Futura", "Helvetica", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
    -webkit-appearance: none;
}

.fieldArea.noMinHeight {
    min-height: 0;
}

.fieldArea.noMinHeight textarea {
    min-height: 0;
}

.fieldArea textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}
.fieldArea textarea::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
}
.fieldArea textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}
.fieldArea textarea:-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.fieldArea.active textarea::-webkit-input-placeholder {
    opacity: 0;
}
.fieldArea.active textarea::-moz-placeholder {
    opacity: 0;
}
.fieldArea.active textarea:-ms-input-placeholder {
    opacity: 0;
}
.fieldArea.active textarea:-moz-placeholder {
    opacity: 0;
}

.fieldArea textarea + label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
}

.fieldArea textarea + label.error {
    color: #ec392f;
}
