html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Futura", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  list-style: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(255,255,255,0.8);
}
::-moz-placeholder { /* Firefox 19+ */
  color: rgba(255,255,255,0.8);
}
:-ms-input-placeholder { /* IE 10+ */
  color: rgba(255,255,255,0.8);
}
:-moz-placeholder { /* Firefox 18- */
  color: rgba(255,255,255,0.8);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

textarea {
  resize: none;
}

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
